import * as React from "react"
import LandingPage from "./AllComponents/LandingPage/LandingPage";
import Layout from "./Common/Container";
import SEO from "./Common/SEO/seo";
import Chatbot from './Common/Chatbot/Chatbot';

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Doc & Support"
        description="Wanclouds VPC+ Doc and support, contact us for more information"
        keywords="blogs, multi cloud, DRaaS, disaster recovery, newsfeed, MaaS, cloud migrations, cloud computing, public cloud, multi-cloud migrations, tech news, vpc"
      />
     <Chatbot />
      <Layout>
        <section className="section">
          <LandingPage/>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage;
